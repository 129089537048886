import React, {useState} from 'react';
import {useTheme} from "@mui/material/styles";
import {Cancel, Chat, CheckCircle, Close, Info, PanoramaFishEye, Warning} from "@mui/icons-material";
import {getSpacing, getStatusAccentColor, getStatusColor} from "fit/system/UtilityFunctions";
import {Collapse, Typography} from "@mui/material";

const AlertInlineMessage=({visible, status, message, children, style})=>{
    const theme = useTheme();
    const getIcon=(status, style)=>{
        switch(status){
            case 'success':
                return <CheckCircle style={style} fontSize={'small'}/>;
            case 'info':
                return <Info style={style} fontSize={'small'}/>;
            case 'warning':
                return <Warning style={style} fontSize={'small'}/>;
            case 'error':
                return <Cancel style={style} fontSize={'small'}/>;
            case 'description':
                return <Chat style={style} fontSize={'small'}/>;
            case 'noAction':
                return <PanoramaFishEye style={style} fontSize={'small'}/>;
            default:
                return <Info style={style} fontSize={'small'}/>;
        }
    };
    const getColors=()=>{
        const setStatus = status == null || status === '' ? 'noAction' : status;
        const p = theme.palette;
        //console.log('MUI THEME', theme);console.log('THEME MODE', p.mode);
        if(status === 'description'){
            return {bgColor: '#ccc', color: '#111'}
        } else{
            return{bgColor: p[setStatus].main, color: p[setStatus].contrastText}
        }
    }

    let {color, bgColor} = getColors();
    const iconAlignment = { color: color};
    const radius = `${theme.shape.borderRadius}px`;
    const headerStyle={color: color, fontWeight: 'normal'};
    const messageStyle={color: color};
    const alertStyle = {
        position: 'relative',
        background: bgColor,
        color: color,
        padding: getSpacing('iconAlignment'), //6px
        borderRadius: radius,
        MozBorderRadius: radius,
        WebkitBorderRadius: radius,
        width : '100%',
        display: 'flex',
        alignItems: 'center'
        /* 'border': `1px solid ${color}` */
    };
    const displayIcon = getIcon(status, iconAlignment);
    if(message != null){
        message = <Typography variant={'body1'} style={messageStyle}>
            {message}
        </Typography>
    }
    return (
        <Collapse in={visible}>
            <div style={{...style}}>
                <div style={alertStyle}>
                    <div style={{
                        display: 'inline-grid',
                        width: '100%',
                        gridTemplateColumns: '24px 1fr',
                    //    border: '1px dashed #fff'
                    }}>
                        <div style={{
                            gridColumn: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            alignContent: 'center',
                       //     border: '1px dotted #000'
                        }}>
                            {displayIcon}
                        </div>
                        <div style={{
                            gridColumn: 2,
                            display: 'flex',
                            justifyContent: 'left-start',
                            alignContent: 'center',
                        //    border: '1px solid #000'
                        }}>
                            <Typography variant={"body1"} style={headerStyle}>
                                {message}
                            </Typography>
                        </div>
                    </div>

                    {children}
                </div>
            </div>
        </Collapse>
    )
}

export default AlertInlineMessage;