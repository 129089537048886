import React, {useMemo} from "react";
import User from 'fit/system/User';
import {PermissionRouteTranslator} from "fit/system/FitJumboTranslators";
import {connect} from 'react-redux'
import {Routes, Route} from 'react-router-dom';
import DeniedRoute from "fit/routes/system/DeniedRoute";
import {PERM_DEPT, PERM_TEAM} from "../fit/system/FITConstants";


const FitAppRoutes = () => {
    let user = new User();
    const loggedIn = user.getLoggedInStatus();
    const deviceAccount = user.getDeviceAccount();

    //Updated Memo 2024-10-03
    /*
    const teams = user.getPermissions(PERM_TEAM);
    const depts = user.getPermissions(PERM_DEPT);
    const permittedRoutes = useMemo(()=>{
        console.log('LOADING NEW ROUTES :: TEAMS', teams, 'DEPTS', depts);
        return PermissionRouteTranslator(loggedIn, deviceAccount);
    },[teams, depts]);

     */
    const permittedRoutes = PermissionRouteTranslator(loggedIn, deviceAccount);
    return (
        <Routes>
            {
                permittedRoutes.map((route,key) =>{
                    return <Route path={route.path} element={route.element} key={key}/>
             })
            }
            <Route path={'*'} element={<DeniedRoute/>}/>
        </Routes>
    );
};

const mapStateToProps = (state) => {
    return {FITUser: state.FITUser};
};
export default connect(mapStateToProps)(FitAppRoutes);