export function setUpdateAvailable(version, updateAvailable, level, timestamp){
    return {
        type: 'SET_UPDATE_AVAILABLE',
        version: version,
        updateAvailable: Boolean(updateAvailable),
        updateLevel: parseInt(level),
        timestamp
    };
}

export function setIgnore(timestamp){
    return {
        type: 'SET_IGNORE',
        ignoreTimestamp: timestamp
    }
}