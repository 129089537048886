import React, {Suspense, useEffect} from "react";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "react-query";
import JumboApp from "@jumbo/components/JumboApp";
import AppLayout from "./AppLayout";
import JumboTheme from "@jumbo/components/JumboTheme";
import {history} from './redux/store';
import {SnackbarProvider} from "notistack";
import AppProvider from "./AppProvider";
import {config} from "./config/main";
import JumboRTL from "@jumbo/JumboRTL/JumboRTL";
import ClientStore from "fit/system/ClientStore";
import User from "fit/system/User";
import FitAppRoutes from "./FitAppRoutes";
import ButeoSuspenseFallback from 'fit/components/System/ButeoSuspenseFallback';
import ConnectionErrorAlert from "fit/components/ConnectionErrorAlert";
import AppAnnouncementModal from "fit/components/AppAnouncement/AppAnnouncementModal";
import VersionChecker from "fit/components/System/VersionChecker";

//FIT User preferences


const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});


const App =()=>{
    const user = new User(false);
    const authorized = user.getLoggedInStatus();
    useEffect(()=>{
        //Onload: determine if the user is already logged in. Forward them to the requested route
        //Or display the login/public facing forms
        user.persistClientState();
    },[]);

    const layout = authorized ? config.authorizedLayout : config.defaultLayout;
    //const theme = initUserTheme();
    //console.log('STANDARD THEME', config.theme);    console.log('CUSTOM THEME', theme);
    return (
        <QueryClientProvider client={queryClient}>
            <Provider store={ClientStore}>
                <BrowserRouter history={history}>
                    <AppProvider>
                        <JumboApp activeLayout={layout}>
                            <JumboTheme>
                                <JumboRTL>
                                    <SnackbarProvider>
                                        <AppLayout>
                                            <VersionChecker/>
                                            <ConnectionErrorAlert/>
                                            <Suspense
                                                fallback={
                                                    <ButeoSuspenseFallback/>
                                                }
                                            >
                                            <FitAppRoutes/>
                                            <AppAnnouncementModal/>
                                            </Suspense>
                                        </AppLayout>
                                    </SnackbarProvider>
                                </JumboRTL>
                            </JumboTheme>
                        </JumboApp>
                    </AppProvider>
                </BrowserRouter>
            </Provider>
        </QueryClientProvider>
    );
}

export default App;
