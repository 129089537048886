import React, {useState, useEffect} from 'react';
import JumboThemeSidebarContext from "./JumboThemeSidebarContext";
import {createTheme} from "@mui/material/styles";

const JumboThemeSidebar = ({children, init, themeColorID}) => {
    const [sidebarTheme, setSidebarTheme] = useState(init);

    useEffect(()=>{
        setSidebarTheme(init);
    },[init]);

    const themeSidebarContextValue = React.useMemo(() => {
        return {
            sidebarTheme: createTheme(init),
            setSidebarTheme: setSidebarTheme,
        }
    }, [sidebarTheme, setSidebarTheme, themeColorID, init]);

    return (
        <JumboThemeSidebarContext.Provider value={themeSidebarContextValue}>
            {children}
        </JumboThemeSidebarContext.Provider>
    );
};

export default JumboThemeSidebar;