import User from './User';
import SecureConnect from "./SecureConnect";
import {APP_VERSION} from "./FITConstants";
import ClientStore from "./ClientStore";
import {setIgnore, setUpdateAvailable} from 'app/redux/actions';
import {boolToInt} from "./UtilityFunctions";


class VersionControl{
    constructor() {
        this.storageLocation = '_VersionControlTimestamp';
        //How often does the system check for a new update?
        this.intervalSeconds = 7200; //2 hours
        this.refreshRate = this.intervalSeconds*1000; //milliseconds
        //This is the difference between when update was received and user ignored update notice
        //This is used to calculate whether to display the notice again.
        //Prevents the notice from being displayed again within timeframe
        this.ignoreDifferenceTimeMinutes = 5;
        //difference converted to milliseconds (times stored from Date.now())
        this.ignoreHideTime = this.ignoreDifferenceTimeMinutes*60*1000;
    }
    getVersion(){
        const {version} = ClientStore.getState();
        return version;
    }
    splitVersion(version){
        return version.split('.');
    }
    checkForAppUpdates(displayNotifications=false, displaySuccessMessages=false){
        const sc = new SecureConnect('system.php?action=getAppVersion');
        sc.setDisplayNotifications(displayNotifications);
        sc.setDisplaySuccessMessages(displaySuccessMessages);
        sc.connect().then(json=>{
            if(sc.getCompleted(json)){
                const {appVersion, rollBack} = sc.getData(json);
                const clientVersionInfo = this.splitVersion(APP_VERSION);
                const sysVersionInfo = this.splitVersion(appVersion);

                if(appVersion !== APP_VERSION) {
                    //Version difference, update state
                    if (Boolean(rollBack) || parseInt(sysVersionInfo[0]) > parseInt(clientVersionInfo[0])) {
                        //New system version is a significant upgrade over the client.
                        //Recommendation level maxed out. Mandatory for Devices
                        //Warning Status = 0, Success Status = 1
                        this.setUpdateAvailable(sysVersionInfo, true, 0);
                    } else if (parseInt(sysVersionInfo[1]) > parseInt(clientVersionInfo[1])) {
                        //New system version has new features. Not mandatory update
                        //Recommendation level: recommended. Not mandatory
                        this.setUpdateAvailable(sysVersionInfo, true, 1);
                    } else if (parseInt(sysVersionInfo[2]) > parseInt(clientVersionInfo[2])) {
                        //Patched. Recommended
                        this.setUpdateAvailable(sysVersionInfo, true, 2);
                    } else {
                        //Match or Client greater than system. Do nothing
                        this.setUpdateAvailable(APP_VERSION, false, -1);
                    }
                }
            }
        })
    }
    createVersionControlData(ignored = false){
        return {
            timestamp: Date.now(),
            ignored: boolToInt(ignored),
        }
    }
    specificFunctionName(){
        const obj = {timestamp: Date.now()}
        localStorage.setItem(this.storageLocation, JSON.stringify(obj));
    }
    updateClient(){
        //Update the client
        const user = new User();
        console.log('UPDATING THE CLIENT');
        user.broadcastReload();
    }
    ignoreUpdate(){
        let obj = JSON.parse(localStorage.getItem(this.storageLocation));
        const now =  Date.now();
        obj.ignoreTimestamp = now;
        this.storeUpdate(obj);
        ClientStore.dispatch(setIgnore(now));
    }
    checkShowUpdate(){
        //Check the timestamp for the latest update and the last time the update was ignored
        //determine if the difference exceeds the hidetime
        //if it does, return true. Else return false
        const {timestamp, ignoreTimestamp} = this.getVersion();
        if(ignoreTimestamp == null){
            return true;
        }
        const difference = timestamp - ignoreTimestamp;
        //console.log(timestamp, ignoreTimestamp, 'DIFF = ', difference, 'IGNORE TIME', this.ignoreHideTime);
        return this.ignoreHideTime < difference;
    }
    storeUpdate(versionNumber, updateAvailable, updateLevel, timestamp){
        this.setUpdate({versionNumber, updateAvailable, updateLevel, timestamp, ignoreTimestamp: null});
    }

    setUpdateAvailable(versionNumber, updateAvailable = false, level=-1){
        const timestamp = Date.now();
        this.storeUpdate(versionNumber, updateAvailable, level, timestamp);
        ClientStore.dispatch(setUpdateAvailable(versionNumber, updateAvailable, level, timestamp));
    }
    setUpdate(obj){
        localStorage.setItem(this.storageLocation, JSON.stringify(obj));
    }
}

export default VersionControl;