/*
	FITUSER REDUCER FOR MANAGING USER SETTINGS
*/
import {APP_VERSION} from "fit/system/FITConstants";
import VersionControl  from "fit/system/VersionControl";
const vc = new VersionControl();
const appVersion = vc.splitVersion(APP_VERSION);

const init = {
    updateAvailable: false,
    updateLevel: -1,
    version: appVersion,
    timestamp: null,
    ignoreTimestamp: null,
};


const FITUser = (state = init, action) =>{
    switch(action.type){
        case 'SET_UPDATE_AVAILABLE':
            const {updateAvailable, updateLevel, version, timestamp} = action;
            const v = {updateAvailable, updateLevel, version};
            return {
                ...state,
                updateAvailable,
                updateLevel,
                version,
                timestamp
            }
        case 'SET_IGNORE':
            return {
                ...state,
                ignoreTimestamp: action.ignoreTimestamp
            }
        default:
            return state;
    }
};

export default FITUser;