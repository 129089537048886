import React, {useEffect} from 'react';
import VersionControl from "fit/system/VersionControl";
import User from 'fit/system/User';
import useAnnouncementStore, {DISPLAY_OPTIONS} from "../AppAnouncement/AnnouncementStore";
import {connect} from "react-redux";


const VersionChecker=({debug = false})=>{
    const user = new User();
    const vc = new VersionControl();
    let updateInterval;
    const setDisplay = useAnnouncementStore(s=>s.setDisplay);
    const closeModal = useAnnouncementStore(s=>s.closeModal);
    const modalHeader = useAnnouncementStore(s=>s.modalHeader);
    const {display} = modalHeader;
    const version = vc.getVersion();
    const deviceAccount = user.getDeviceAccount();
    const {activeWorkout} = user.getFitUserStore();
    const {updateAvailable, timestamp, ignoreTimestamp} = version;
    const authorized = user.getLoggedInStatus();
    //Start checking for new versions of the app

    const startInterval=()=>{
        updateInterval = setInterval(()=>vc.checkForAppUpdates(), vc.refreshRate);
    }

    useEffect(()=>{
        if(authorized && updateInterval == null && !updateAvailable){
            startInterval();
        }
    },[])

    useEffect(()=>{
        if(authorized) {
            if(updateInterval == null && !updateAvailable) {
                //Start checking if there's no update available
                startInterval();
            }
        } else{
            console.log('CLEARING UPPDATE INTERVAL');
            clearInterval(updateInterval);
        }
    },[authorized, updateAvailable]);

    //Display Modal if criteria met (deviceAccount, updateAvailable, No ActiveWorkout)
    useEffect(()=>{

        //Display the modal
        const showUpdate = vc.checkShowUpdate();
        if(debug) {
            console.log('VERSION', version);
            console.log('UPDATE AVAILABLE', updateAvailable, 'DEVICE?', deviceAccount, 'SHOW?', showUpdate, 'ACTIVE WORKOUT?', activeWorkout);
        }
        if(deviceAccount && updateAvailable && showUpdate && !activeWorkout){
            setDisplay(true, DISPLAY_OPTIONS.updateNotice);
        }
        if(activeWorkout && display){
            closeModal()
        }
    },[updateAvailable, activeWorkout, timestamp])


    if(debug){
        return (
            <div>
                <h3>Authorized: {authorized ? 1: 0}</h3>
                <h3>Version</h3>
                {JSON.stringify(version, null, ' ')}
            </div>
        )
    }

    return (
        <div/>
    )
}

const mapStateToProps=(state)=>{
    return {FITUser: state.FITUser, version: state.version};
}

export default connect(mapStateToProps)(VersionChecker);


