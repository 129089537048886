import React from 'react';
import User from 'fit/system/User';
import {useTheme} from "@mui/material";
import {Download} from "@mui/icons-material";
import {Button} from "@mui/material";
import Alert from "fit/components/Alert";
import {getSpacing} from "fit/system/UtilityFunctions";
import VersionControl from "fit/system/VersionControl";
import {connect} from "react-redux";

const VersionAlert=({style})=>{
    const vc = new VersionControl();
    const theme = useTheme();
    const {updateAvailable, updateLevel} = vc.getVersion();

    const color = theme.palette.primary.contrastText;
    const sx = {color, position: 'relative', top: getSpacing('iconAlignment')}
    const imperative = updateLevel < 1;
    const status = imperative ? 'warning' : 'success';
    const icon = imperative ? <Download sx={sx}/> : <Download sx={sx}/>



    return (
        <Alert
            icon={icon}
            status={status}
            style={style}
            visible={updateAvailable}
            headline={'New Update Available'}
            message={'Get The Latest Version Now'}
        >
            <Button
                variant={'contained'}
                color={'primary'}
                fullWidth
                onClick={()=>vc.updateClient()}
            >
                <Download/> Install
            </Button>
        </Alert>
    )

}

const mapStateToProps=(state)=>{
    return {version: state.version};
}
export default connect(mapStateToProps)(VersionAlert);