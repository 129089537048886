import React from 'react';
import {useTheme} from "@mui/material";
import {Download} from "@mui/icons-material";
import {Button} from "@mui/material";
import AlertSmall from "fit/components/AlertSmall";
import VersionControl from "fit/system/VersionControl";
import {connect} from "react-redux";


const HeaderSmallAlert=({style})=>{
    const theme = useTheme();
    const vc = new VersionControl();
    const {updateAvailable, updateLevel} = vc.getVersion();
    const color = theme.palette.primary.contrastText;
    const status = updateLevel < 1 ? 'warning' : 'success';
    return (
        <AlertSmall
            style={style}
            status={status}
            icon={<Download sx={{color}}/>}
            visible={updateAvailable}
            message={<Button sx={{color: '#fff'}} variant={'text'} onClick={()=>vc.updateClient()}>Install Update</Button>}
            tip={'New Version Available For Download'}
        />
    )
}

//export default HeaderSmallAlert;

const mapStateToProps=(state)=>{
    return {version: state.version};
}
export default connect(mapStateToProps)(HeaderSmallAlert);