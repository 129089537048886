import React, {useState, useEffect} from 'react';
import {getStatusIcon, getSpacing} from "fit/system/UtilityFunctions";
import {useTheme, Collapse, IconButton, Grid, Tooltip} from "@mui/material";


const AlertSmall=({visible, tip, message, status, extended = false, allowExtended = true, children, icon, style})=>{
    const [extendedDisplay, setExtendedDisplay] = useState(extended);
    const theme = useTheme();

    useEffect(()=>{
        setExtendedDisplay(false);
    },[visible]);

    const setDefaultText=(text, defaultText)=>{
        return text == null || text === '' ? defaultText : text;
    }



    const setStatus = setDefaultText(status, 'noAction');
    const tipMessage = setDefaultText(tip, 'Click for more information');
    const p = theme.palette;
    const backgroundColor = p[setStatus].main;
    const color = p[setStatus].contrastText;
    const displayIcon = icon != null ? icon : getStatusIcon(status, {color: color});

    const handleExtended=(bool)=>{
        if(allowExtended){
            setExtendedDisplay(bool);
        }
    }

    const divStyle = {
        ...style,
        backgroundColor,
        color,
        borderRadius: '24px'
    }
    return (
        <Collapse in={visible}>
            <div style={divStyle}>
                <div style={{
                    display: 'inline-grid',
                    gap: 0
                }}
                     >
                    <div style={{
                        gridColumn: 1,
                        gridRow: 1
                    }}>
                        <Tooltip title={tipMessage} placement={'bottom-start'}>
                            <IconButton onClick={()=>handleExtended(!extendedDisplay)}>
                                {displayIcon}
                            </IconButton>
                        </Tooltip>


                    </div>
                    <div style={{
                        gridColumn: 2,
                        gridRow: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        overflow: 'hidden'
                    }}>
                        <Collapse orientation={'horizontal'} in={extendedDisplay}>
                            <div style={{
                                overflow: 'hidden',
                                display: 'block',
                                paddingRight: getSpacing('small'),
                                lineHeight: 1.75,
                                fontWeight: 400,
                                textTransform: 'uppercase',
                                whiteSpace: 'nowrap'
                            }}>
                                {message}
                                {children}
                            </div>
                        </Collapse>
                    </div>

                </div>
            </div>
        </Collapse>

    )

}

export default AlertSmall;