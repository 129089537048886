import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import FITUser from "./FITUser";
import MeasurementUnits from "./MeasurementUnits";
import Version from "./Version";

const exportReducers = history => {
    return combineReducers({
        router: connectRouter(history),
        FITUser: FITUser,
        version: Version,
        MeasurementUnits: MeasurementUnits,
    });
};

export default exportReducers;

