export function login(authorized, userInfo){
	return {type: 'LOGIN', authorized, userInfo};
}

export function logout(){
	return {type: 'LOGOUT', authorized: false, userInfo: {}};
}

//Update the user's permissions - when account acted on by someone else
export function updateUser(authorized, userInfo){
	return {type: 'UPDATE_USER', authorized: authorized, userInfo: userInfo};
}

export function setOnline(bool){
	return {type: 'SET_ONLINE', online: Boolean(bool)};
}

export function setRedirectURL(url){
	return {type: 'SET_REDIRECT_URL', authorized: false, userInfo: {}, url: url};
}

export function setPending(pendingValue){
	return {type: 'SET_PENDING', authorized: false, userInfo: {}, pendingStatus: pendingValue};
}
export function setWorkout(bool){
	return {type: 'SET_WORKOUT', activeWorkout: Boolean(bool)}
}

export function updateActorCheck(setting, value){
	return {type: 'UPDATE_ACTOR_CHECK', setting: setting, value: value};
}

export function updateUserDetails(userInfo){
	return {type: 'UPDATE_USER_DETAILS', userInfo: userInfo}
}

//Update the user's interface with new details - when acted on by user
/*
export function lockScreen(){
	return {type: 'LOCK_SCREEN'};
}

 */









