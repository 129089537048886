/*
	FITUSER REDUCER FOR MANAGING USER SETTINGS
*/
import {APP_VERSION} from "fit/system/FITConstants";

const startingUserSettings = {
	authorized: false, //is logged in
	//activeWorkout: Prevents automatic updates of device accounts when set to true
	activeWorkout: false, //set to true when a workout is assigned to a device or has the interface pulled up
	online: true, //connection active
	account: {}, //AIT User Info (Settings/Permissions)
	pending: 0, //pending session check (0=> not checked, 1=>in progress, 2=>Complete)
	redirect: '',  //route to redirect user depending on authorization [DEPRECATED?]
	pc: false, //pending complete (bool) [DEPRECATED?]
};


const FITUser = (state = startingUserSettings, action) =>{
	switch(action.type){
		case 'UPDATE_USER':
			//Update user permissions occurs when user account is acted on
			return {
				...state,
				authorized: action.authorized,
				account: action.userInfo,
				pending: 0,
			//	pc: true, //pending complete

			};
		case 'SET_ONLINE':
			//Updates the state. Tells whether the app is online or offline.
			return {
				...state,
				online: action.online
			}
		case 'UPDATE_USER_DETAILS':
			//Update user details (name, phone, email, mailing address) when user edits their own account
			return {
				...state,
				account: action.userInfo
			};
		case 'LOGOUT':
			return {
				...state,
				authorized: false,
				account: {},
				pending: 2,
			//	pc: true,

			};
		case 'LOCK_SCREEN':
			return {
				...state,
				authorized: false,
				pending: 2,
			//	pc: true,
			};
		case 'SET_PENDING':
			let pcStatus = action.pendingStatus > 1 ;
			return {
				...state,
				authorized: false,
				pending: action.pendingStatus,
			//	pc: pcStatus,

			};
		case 'SET_REDIRECT_URL':
			return {
				...state,
				redirect: action.url
			};
		case 'SET_WORKOUT':
			return {
				...state,
				activeWorkout: action.activeWorkout
			}
		default:
			return state;
	}
};

export default FITUser;
