import ClientStore from "./ClientStore";
import UserPreferences from "./UserPreferences";
import {isEmpty} from "./UtilityFunctions";
import {parse} from "stylis";

class MeasurementUnits{
    constructor(getDecimal = true) {
        const {unitData, loaded} = ClientStore.getState().MeasurementUnits;
        this.unitData = loaded ? unitData : [];
        //IDs for differing measurement Units
        this.TIME_ID = 1;
        this.POUND_ID = 2;
        this.KILOGRAM_ID = 3;
        this.INCHES_ID = 4;
        this.FEET_INCHES_ID = 5;
        this.YARD_ID = 6;
        this.MILE_ID = 7;
        this.REP_ID = 8; //Exercise_units Database ID associated with Reps
        this.CENTIMETER_ID = 9;
        this.DECIMETER_ID = 10;
        this.KILOMETER_ID = 11;
        this.METER_ID = 12;
        this.MILLIMETER_ID = 13;
        this.NAUTICAL_MILE_ID = 14;
        this.FEET_DECIMAL = 15;
        //Types for filtering measurements
        this.repTypeID = 1;
        this.distanceTypeID = 2; //For labeling
        this.timeTypeID = 3;
        this.weightTypeID = 4; //For determining if the unit Type is a weight
        this.measurementSets = []; //List of measurement Objects and their statuses
        this.failedConversionValue = -9001
        this.decimalChar = '.';
        if(getDecimal) {
            const userPrefs = new UserPreferences();
            this.decimalChar = userPrefs.getDecimalCharacter();
        }
        this.feetInchesInputDelimiter = '|'; //For creating feet/inches input names
        this.debugLog = false;
        /*
            Measurement statuses are as follows:
            {name (string), measurementID (int), valid (t/f)}
        */
    }
    getMeasurementTypes(){
        if(this.hasNoExerciseUnits()){
            return [];
        }
        let units = [];
        let includedUnits = [];
        this.unitData.forEach(mUnits =>{
            const {typeID, type} = mUnits;
            if(includedUnits.includes(typeID) === false){
                units.push({typeID, name: type});
                includedUnits.push(typeID);
            }
        });
        //console.log('RETRIEVED MEASUREMENT TYPES', units);
        return units;
    }
    getMeasurementType(typeID){
        //Return The measurement Type String based on the typeID
        if(this.hasNoExerciseUnits()){
            return null;
        }
        const unit = this.unitData.find(unit =>parseInt(unit.typeID) === parseInt(typeID));
        return unit != null ? unit.type : null;
    }
    getMeasurementUnitsByTypeID(unitTypeID){
        if(this.#checkID(unitTypeID) && !isEmpty(this.unitData)) {
            return this.unitData.filter(eu => parseInt(eu.typeID) === parseInt(unitTypeID));
        }
        return [];
    }
    getMeasurementUnitByID(unitID){
        return this.getMeasurementUnitData(unitID);
    }
    getButtonUnitLabel(unitID){
        const isTime = this.isTime(unitID);
        const feetInches = this.isFeetInches(unitID);
        const {abbreviation} = this.getMeasurementUnitByID(unitID);
        return isTime || feetInches ? '' : abbreviation;
    }
    getAlternativeUnit(unitID){
        //Returns the AltUnitID from the measurementID - or returns the original iif none provided
        if(this.#checkID(unitID)){
            const unitData = this.getMeasurementUnitByID(unitID);
            if(this.debugLog) {
                console.log('ALT UNIT', unitData);
            }
            const {altUnitID} = unitData;
            return altUnitID != null ? altUnitID : unitID;
        }
        return unitID;
    }
    getLimitedAlternatives(unitID, unitIDList){
        //get the measurement type for the original unit ID, and then filter all measurements for units that also belong in the unitIDList
        const {typeID} = this.getMeasurementUnitByID(unitID);
        return this.unitData.filter(unit => unit.typeID === typeID && unitIDList.includes(unit.id));
    }
    getMeasurementAlternatives(unitID, ignoreFeetInches = false){
        //Returns a list of measurements within the same measurementGroup
        if(this.#checkID(unitID)){
            const {measurementGroup} = this.getMeasurementUnitByID(unitID);
            const unitData =  this.unitData.filter(mu => parseInt(mu.measurementGroup) === parseInt(measurementGroup));
            const length = this.isLength(unitID);
            if(!length || (length && ignoreFeetInches === false)){
                return unitData;
            } else{
                //Filter out feet/inches
                return unitData.filter(mu =>parseInt(mu.id) !== parseInt(this.FEET_INCHES_ID));
            }
        }
        return [];
    }
    getTimeTypeString(timeString){
        //RETURN SECONDS, MINUTES, HOURS STRINGS BASED ON THE TIME VALUE SUPPLIED
        //This can be placed in the InputAdornment of a Textfield
        const sections = timeString.split(':');
        if(sections.length === 2){
            return 'MM:SS';
        } else if(sections.length === 3){
            return 'HH:MM:SS';
        } else{
            return 'secs';
        }
    }
    getRepSetting(id){
        if(this.#checkID(id)){
            const index = this.unitData.findIndex(item => parseInt(item.id) ===  parseInt(id));
            if(index > -1){
                return this.unitData[index];
            }
        }
        return {};
    }
    getRepTypes(){
        if(this.hasNoExerciseUnits()){
            return [];
        }
        return this.unitData.filter(eu => parseInt(eu.typeID) === this.repTypeID);
    }
    getMeasurementUnitData(unitID){
        if(this.#checkID(unitID)){
            const index = this.unitData.findIndex(eu => parseInt(eu.id) === parseInt(unitID));
            if(index > -1){
                return this.unitData[index];
            }
        }
        return {};
    }
    getActiveWorkoutRepLabel(repUnitID){
        //Return distance labels for the activeWorkout form
        //Standard Lifting reps and time reps return blank strings
        let repLabel = '';
        if(this.#checkID(repUnitID)){
            const index = this.unitData.findIndex(units => parseInt(units.id) === parseInt(repUnitID));
            if(index > -1){
                const repData = this.unitData[index];
                repLabel = parseInt(repData.typeID) === this.distanceTypeID ? repData.activeWorkoutRepLabel : '';
            }
        }
        return repLabel;
    }
    getRepUnits(repTypeID){
        if(this.#checkID(repTypeID)) {
            const filtered = this.unitData.filter(item => parseInt(item.repTypeID) === parseInt(repTypeID) && parseInt(item.rep) === 1);
            if (filtered.length > 0) {
                return filtered;
            }
        }
        return [];
    }
    validateMeasurementInput(value, measurementID, precision = 0, validateWeight = true, log = false){
        measurementID = parseInt(measurementID);
        const exUnitData = this.getMeasurementUnitByID(measurementID);
        const stringLength = `${value}`.length;
        const isTime = this.isTime(measurementID);
        const isFeetInches = this.isFeetInches(measurementID);
        const isWeight = this.isMass(measurementID);
        const output = this.debugLog || log;
        if(output) {
            console.log('VALIDATING MUNITS', value, measurementID, precision);
            console.log(value, 'exUnitData', exUnitData);
            console.log('TIME?', isTime, 'FEET/INCHES?', isFeetInches, 'Weight?', validateWeight);
        }
        if(isTime){
            const sections = value.split(':');
            let index, format;
            if(sections.length === 2){
                //MM:SS
                format = 'MM:SS';
                index = value.search(/^\d{1,2}:\d{2}/);
            } else if(sections.length === 3){
                index = value.search(/^\d{1,2}:\d{2}:\d{2}/);
                format = 'HH:MM:SS';
                //HH:MM:SS
            } else{
                //SECONDS
                format = 'SS';
                index = value.search(/^\d{1,2}/);
            }
            //Matching Pattern Found
            const valid = index !== -1;
            if(output) {
                console.log('VALIDATING TIME', value, format, valid);
            }
            return valid;
        } else if (isFeetInches){
            if(output) {
                console.log('VALUE', value);
            }
            const {rawInches} = this.explodeFeetInchesString(value);
            return rawInches > 0;
        } else if(isWeight){
            const userPrefs = new UserPreferences();
            const numberValue = parseFloat(userPrefs.convertNumber(value, true));
            const testValue = stringLength > 0 && numberValue > 0;
            if(output) {
                console.log('VALIDATING WEIGHT', value, numberValue);
            }
            //const
            if(userPrefs.validateWeight && validateWeight){
                const weightValid = measurementID === this.POUND_ID ? numberValue % userPrefs.lbUnit === 0 : numberValue % userPrefs.kgUnit === 0;
                return weightValid && testValue;
            } else {
                return testValue;
            }
        } else {
            //Numeric - make sure the value isn't empty and number is greater than 0
            return stringLength > 0 && parseFloat(value) > 0
        }
    }
    isRep(measurementID){
        return parseInt(measurementID) === parseInt(this.REP_ID);
    }
    isTime(measurementID){
        return parseInt(measurementID) === parseInt(this.TIME_ID);
    }
    isMass(measurementID) {
        if(this.#checkID(measurementID)){
            const unitData = this.getMeasurementUnitByID(measurementID);
            return this.isMassByTypeID(unitData.typeID);
        }
        return false;
    }
    isMetric(measurementID){
        if(this.#checkID(measurementID)){
            const unitData = this.getMeasurementUnitByID(measurementID);
            return parseInt(unitData.metric) === 1;
        }
        return false;
    }
    isMassByTypeID(typeID){
        return this.#checkID(typeID) ? this.#idMatch(typeID, this.weightTypeID) : false;
    }
    isLength(measurementID){
        return this.checkTypeID(measurementID, this.distanceTypeID);
    }
    isFeetInches(unitID){
        return parseInt(unitID) === this.FEET_INCHES_ID;
    }
    hasNoExerciseUnits(){
        return this.unitData == null || this.unitData.length < 1
    }
    getUnitData(){
        return this.unitData;
    }


    checkTypeID(measurementID, typeID){
        if(this.#checkID(measurementID)){
            const unitData = this.getMeasurementUnitByID(measurementID);
            return parseInt(unitData.typeID) === parseInt(typeID);
        }
        return false;
    }
    handleValue(value, measurementID, precision = 0){
        measurementID = parseInt(measurementID);
        //TIME
        if(this.isTime(measurementID)){
            return this.handleTimeValue(value, precision);
        } else if(this.isFeetInches(measurementID)){
            const {submitString} = this.explodeFeetInchesString(value, precision);
            return submitString;
        } else{
            return this.handleNumericValue(value, precision);
        }
    }
    formatTimeString(seconds, precision = 3){
        seconds = Math.abs(parseFloat(seconds));
        //Receive time value in seconds return in time format
        //For leaderboards etc
        const hrs = Math.floor(seconds/3600);
        const min = Math.floor((seconds - (hrs*3600))/60);
        const sec = Number(parseFloat(seconds - (hrs*3600) - (min*60)).toFixed(precision));
        const HH = hrs >= 10 ? hrs : hrs > 0 ? `0${hrs}` : '';
        const MM = min >= 10 ? min : min > 0 ? `0${min}` : '';
        const SS = sec >= 10 ? sec : sec > 0 ? `0${sec}` : '';
        if(hrs > 0){
            return `${HH}:${MM}:${SS}`;
        } else if(min > 0){
            return `${MM}:${SS}`;
        } else{
            return `0:${SS}`;
        }
    }
    handleTimeValue(value, precision = 0, log = false){
        /*
        HANDLE THE FORMATTING OF TIME INPUTS
        AUTOMATICALLY FORMAT TIME BASED ON COLON AND USER SPECIFIED DECIMAL INPUT
        HH:MM:SS.CCC
        012345678901
        Thoughts, notes:
        -Text Moves left to right (duh) (hh:mm:ss.cc)
        -only allow decimal/period if precision > 0
        -insert colons & decimals @ 2,5,8
        -No Leading Zero, Offset special char positions by 1
        -break down into sections 0(HH), 1(MM),2(SS),3(CCC)
            -Sections 2 & 3 Cap at 59 & 59
        -ONLY CHARACTERS [0-9], : , . are allowed
        -MAYBE START WITH : at position 0 (Assuming Seconds)
            -So if the user inputs 30 and blurs, input will turn to :30 (30 seconds)
    */
        precision = parseInt(precision);
        const max59=(sectionValue)=>{
            const value = parseInt(sectionValue);
            //If value over 59, return 59. Else, provide leading zeros for anything less than 10
            return value > 59 ? `${59}` : value < 10 ? `0${value}` : `${value}`;
        }
        const specialCharPositions = {
            //00:00:00.00
            //01234567890
            0: 2,
            2: 5,
            4: 8
        }
        const decimalChar = this.decimalChar;
        const decimalLocation = value.indexOf(decimalChar);
        const hasDecimal = precision > 0 && decimalLocation > -1;
        const numbersOnly = value.replace(/[^0-9]/g,'');
        let post = hasDecimal ? value.substr(decimalLocation+1, precision).replace(/[^0-9]/g,'') : '';
        const secondChar = value.substr(1,1);
        const secondCharDecimal =  secondChar === decimalChar;
        const noLeadingZero = secondChar === ':' || secondCharDecimal; //NO LEADING ZERO
        const output = this.debugLog || log;
        if(output) {
            console.log('--------------------------------------------------------------');
            console.log('RAW VALUE = ', value, 'NO LEADING ZERO?', noLeadingZero, '# ONLY = ', numbersOnly);
        }
        let sections = [];
        for(let k=0; k<6; k+=2){
            const offset = noLeadingZero && k > 0 ? 1 : 0;
            const specialCharOffset = noLeadingZero ? 1 : 0;
            const stringLength = noLeadingZero && k === 0? 1 : 2;
            const target = numbersOnly.substr(k-offset, stringLength);
            if(target.length > 0){
                const subValue = k > 0 && target.length === 2 ? max59(target) : target;
                sections.push(subValue);
                const potentialDecimalLocation = specialCharPositions[k]-specialCharOffset;
                let nextIsDecimal = value.substr(potentialDecimalLocation, 1) === decimalChar;
                if(output) {
                    console.log(k, noLeadingZero, '_L_ #ONLY =>', numbersOnly, 'OFFSET =', offset, 'STRINGLENGTH =', stringLength, 'TARGET = ', target, 'SPECIAL CHAR POSITION = ', specialCharPositions[k] - offset);
                }
                if(nextIsDecimal){
                    break;
                }
            } else{
                break;
            }
        }
        let noLeadingZeroTime = '';
        let firstSection = '';
        if(noLeadingZero) {
            firstSection = sections.shift();
            const char = secondCharDecimal ? '' : ':';
            noLeadingZeroTime = `${firstSection}${char}`;
        }
        const remainingTime = sections.join(':');
        value=`${noLeadingZeroTime}${remainingTime}`
        /*
            INSTANCES FOR ALLOWING DECIMAL
                -NoLeadingZero: 1.55
                -1:23.23 (Section Length === 1) AND (section[0].length === 2)
                -1:23:45.11 (sectionLength > 1) AND (section[sections.length-1] === 2) (LAST SECTION HAS STRING LENGTH OF 2)
         */
        const test0 = noLeadingZero && sections.length === 0; //No Leading Zero, 1 Second and decimal places (9.44)
        const test1 = sections.length === 1 && sections[0].length === 2; //Has Sections (With or without leading zero) and section has 2 numbers
        const test2 = sections.length > 1 && sections[sections.length-1].length === 2; //Has multiple sections, last section has 2 numbers
        if(hasDecimal && (test0 || test1 || test2)){
            //Input the decimal
            value=`${value}${decimalChar}${post}`;
        }
        return value;
    }
    handleNumericValue(value, precision = 0, log = false){
        value = `${value}`;
        const decimalLocation = this.#getDecimalLocation(value);
        const numbersOnly = value.replace(/[^0-9]/g,'');
        const decimalValue = this.getDecimalValue(value, precision);
        const userPrefs = new UserPreferences();
        const decimal = userPrefs.decimalChar;
        const output = this.debugLog || log;
        if(output) {
            console.log('_________________________');
            console.log('HANDLENUMERIC', 'VALUE = ',value, 'PRECISION=',precision);
            console.log('DECIMAL LOCATION', decimalLocation, 'DECIMALVALUE = ',decimalValue);
            console.log('^^^^^^^^^^^^^^^^^^^^^^^^^^');
        }
        if(precision > 0 && decimalLocation > -1){
            if(output) {
                console.log('___ RETURNING DECIMAL STR ==', `${numbersOnly.substr(0, decimalLocation)}.${decimalValue}`);
            }
            return `${numbersOnly.substr(0,decimalLocation)}${decimal}${decimalValue}`;
        } else{
            return numbersOnly;
        }
    }
    getDecimalValue(value, precision = 0){
        const decimalLocation = this.#getDecimalLocation(value);
        const hasDecimal = precision > 0 && decimalLocation > -1;
        return hasDecimal ? value.substr(decimalLocation+1, precision).replace(/[^0-9]/g,'') : '';
    }
    getErrorMessage(inputValid, measurementID, returnBlankString = false){
        if(this.debugLog) {
            console.log('ERROR MESSAGE', measurementID);
        }
        measurementID = parseInt(measurementID);
        const noErrorString = returnBlankString? ' ' : '';
        if(inputValid){
            return noErrorString;
        } else{
            const isRep = this.isRep(measurementID);
            const isTime = this.isTime(measurementID);
            const isWeight = this.isMass(measurementID);
            const isDistance = this.isLength(measurementID);
            if(this.debugLog) {
                console.log('GETTING ERROR MESSAGE: VALID?', inputValid, 'MEASUREMENT ID = ', measurementID, 'REP?', isRep, 'TIME?', isTime, 'WEIGHT?', isWeight, 'DISTANCE?', isDistance);
            }
            if(isRep){
                return 'Reps required'; //'Please enter the number reps executed';
            } else if(isTime){
                return 'Time required'; // 'Please enter the correct time';
            } else if(isWeight){
                return 'Weight required'; //'Please enter the correct weight amount';
            } else if(isDistance){
                return 'Distance required'; //'Please enter the correct distance';
            } else{
                //Reps
                return 'Value required'; //'Please enter the correct value';
            }
        }
    }
    /*

        FEET/INCHES FUNCTIONS FOR SPLITTING/HANDLING 2 VALUES WITHIN ONE MEASUREMENT



     */
    createFeetInchesSubInputName(name, inputName){
        //Create the sub input names for feet/inches inputs
        //Follow a standard pattern for building/separating input names
        //Keep #getFeetInchesInputNames() Together
        return `${name}${this.feetInchesInputDelimiter}${inputName}`;
    }
    handleFeetInchesInput(fullInputName, value, currentFeetInchesObject, precision = 0) {
        /*
            RETURN FEET/INCHES DATASET: {feet, inches, string, rawInches, feetDecimal}
            1. Receive TextField's/inputs fullName, value, and the current values of the feetDataset
            2. Determine which value was updated based on the input name
            3. Recalculate the dataset based on the updated value
            4. Return the updated/recalculated object
        */

        //1. Receive the input/TextField's fullName, typed value by the user, and the current feetInches Value Object
        const {variableName} = this.#getFeetInchesInputNames(fullInputName);
        //2. Determine values based on inputted variableName, Set values
        const enteredFeet = variableName === 'feet';
        const feet = enteredFeet ? value : currentFeetInchesObject.feet;
        const inches = enteredFeet === false ? value : currentFeetInchesObject.inches;
        //Recalculate & Return Units (found in measurementUnits)
        return this.getFeetInchesValuesObj(feet, inches, precision);
    }
    validateInchesValue(stringValue){
        const inchesValue = parseFloat(stringValue);
        return stringValue.length === '' || (!isNaN(stringValue) && inchesValue >= 0 && inchesValue <= 11.999);
    }
    getFeetDatasetName(fullInputName){
        //Return just the state variableName for a feet/inches input
        //Useful for updating state with feet/inches dataset
        const {name} = this.#getFeetInchesInputNames(fullInputName);
        return name;
    }
    #getDecimalLocation(value){
        const string = `${value}`;
        return string.indexOf(this.decimalChar);
    }
    #idMatch(id, systemID){
        return parseInt(id) === parseInt(systemID);
    }
    #checkID(id){
        return id != null && parseInt(id) > 0;
    }
    #checkNumericValue(value, min=null, max = null){
        if(isNaN(value)){
            return false;
        } else if(min != null && value < min){
            return false;
        } else if(max != null && value > max){
            return false;
        } else{
            return true;
        }
    }
    #initMeasurementValue(value, returnType = 'int'){
        const test =  value != null && !isNaN(value) && `${value}`.length > 0;
        //If value is numeric and has length, return in or float based on returnType, otherwise return 0
        return test ? returnType === 'int' ? parseInt(value) : parseFloat(value) : 0;
    }
    #getFeetInchesInputNames(name){
        //Receives the inputName of an inch or feet input field, find the original input/state name of the variable for updating
        const index = name.indexOf(this.feetInchesInputDelimiter);
        return {name: name.substr(0,index), variableName: name.substr(index+1)}
    }
    #getPrecisionMaskPattern(precision){
        if(parseInt(precision) > 0){
            let str = '';
            for(let k=0; k<precision; k++){
                str+='9';
            }
            return `.${str}`;
        }
        return '';
    }
    explodeFeetInchesString(feetInchString, precision = 3){
        //handle potential blank field
        if(this.debugLog) {
            console.log('explodeFeetInchesString::RECEIVED', feetInchString);
        }
        feetInchString = feetInchString == null || feetInchString === '' ? "'" : feetInchString;
        const set = feetInchString.split("'");
        //Convert Feet Values
        const rawFeet = set[0];
        const feetEntered = rawFeet.length > 0 && !isNaN(rawFeet);
        const feetValue = feetEntered ? parseInt(rawFeet) : 0;
        //Convert Inches Values
        const rawInches = set[1].substr(0,set[1].length-1);
        const inchEntered = rawInches.length > 0 && !isNaN(rawInches);
        const inchValue = inchEntered ? parseFloat(rawInches) : 0;
        //Convert calculated Values
        const submitString = `${feetValue}'${inchValue}"`;
        const inchesValue = feetValue*12+inchValue;
        const feetDecimal = parseFloat((inchesValue/12).toFixed(precision));
        //return obj
        return {
            feet: feetValue,
            feetFieldValue: feetEntered ? feetValue : '',
            inches: inchValue,
            inchFieldValue: inchEntered ? inchValue : '',
            feetDecimal,
            rawInches: inchesValue,
            inputString: feetInchString,
            submitString
        }
    }
    getFeetInchesObj(rawInches, precision = 3, returnNumericValues){
        //Receive raw inches, conver to {feet, inches, string, feet decimal, rawInches}
        if(this.debugLog) {
            console.log('CONVERTING RAW INCHES TO FT/IN', rawInches, 'PRECISION = ', precision);
        }
        rawInches = parseFloat(rawInches);
        const feetValue = parseFloat(Math.floor(rawInches/12));
        const feetDecimal = parseFloat(parseFloat(rawInches/12).toFixed(precision));
        const inchesValue = parseFloat((rawInches - feetValue*12).toFixed(precision));
        const feet = !returnNumericValues && feetValue === 0 ? '' : feetValue;
        const remainingInches = !returnNumericValues && inchesValue === 0 ? '' : inchesValue;
        return {
            feet,
            inches: remainingInches,
            string: `${feet}'${remainingInches}"`,
            feetDecimal,
            rawInches: rawInches
        }
    }
    getFeetInchesValuesObj(feetValueString, inchesValueString, precision=3){
        //Receive values in feet/inches, return variety of formats for the same data
        const hasFeet = feetValueString !== '';
        const hasInches = inchesValueString !== '';
        const feetValue =  hasFeet ? this.handleNumericValue(`${feetValueString}`, 0) : 0;
        let inchValue = hasInches ? this.handleNumericValue(`${inchesValueString}`, precision) : 0;
        if(inchValue >= 12){
            inchValue = 11;
        }
        const inchesDecimal = parseFloat((parseFloat(inchValue)/12).toFixed(precision));
        const feetDecimal = parseInt(feetValue) + inchesDecimal;
        return {
            feet: hasFeet ? feetValue : '',
            inches: hasInches ? inchValue : '',
            string: `${feetValue}'${inchValue}"`,
            feetDecimal: feetDecimal,
            rawInches: feetValue*12+inchValue,
        };
    }
    convertUnits(value, fromUnitID, toUnitID, precision){
        if(this.debugLog) {
            console.log('M_UNITS CONVERT ::', value, 'FROM', fromUnitID, '=>', toUnitID, 'PRECISION = ', precision);
        }
        const {typeID} = this.getMeasurementUnitByID(fromUnitID);
        if(typeID === 2){
            //Length/Distance
            return this.#convertLengthUnit(value, fromUnitID, toUnitID, precision);
        } else if(typeID === 4){
            //MASS
            return this.#convertMassUnit(value, fromUnitID, toUnitID, precision);
        } else{
            //No conversion necessary - return the same value
            //time/Reps
            return value;
        }
    }
    #convertMassUnit(value, fromUnitID, toUnitID, precision = 3, log = false){
        fromUnitID = parseInt(fromUnitID);
        toUnitID = parseInt(toUnitID);
        precision = parseInt(precision);
        const output = this.debugLog || log;
        if(output) {
            console.log('CONVERTING MASS UNITS', 'FROM', fromUnitID, 'TO', toUnitID, 'VALUE', value, 'PRECISION', precision);
        }
        if(fromUnitID === toUnitID){
            const converted = parseFloat(parseFloat(value).toFixed(precision));
            if(output) {
                console.log('SAME UNITS FORMATTING =>', converted)
            }
            return converted;
        }
        let converted = this.failedConversionValue;
        switch (fromUnitID){
            default:
                break;
            case this.POUND_ID:
                switch(toUnitID){
                    default:
                        break;
                    case this.KILOGRAM_ID:
                        converted = parseFloat(value)/2.204623;
                        break;
                }
                break;
            case this.KILOGRAM_ID:
                switch(toUnitID){
                    default:
                        break;
                    case this.POUND_ID:
                        converted = parseFloat(value)*2.204623;
                        break;
                }
        }
        return parseFloat(converted.toFixed(precision));
    }
    #convertLengthUnit(value, fromUnitID, toUnitID, precision = 3){
        fromUnitID = parseInt(fromUnitID);
        toUnitID = parseInt(toUnitID);
        precision = parseInt(precision);

        if(fromUnitID === toUnitID){
            if(fromUnitID === this.FEET_INCHES_ID){
                return value;
            }

            return parseFloat(parseFloat(value).toFixed(precision));
        }
        let converted = this.failedConversionValue;
        switch(fromUnitID){
            default:
                break;
            case this.CENTIMETER_ID: //cm
                switch(toUnitID){
                    default:
                        break;
                    case this.DECIMETER_ID: //decimeter
                        converted = parseFloat(value/10);
                        break;
                    case this.FEET_INCHES_ID: //feet
                        return this.#convertToFeetInchesString(value, fromUnitID, precision);
                        break;
                    case this.FEET_DECIMAL:
                        converted = parseFloat(value * .0328084);
                        break;
                    case this.INCHES_ID: //inch
                        converted = parseFloat(value*.3937008);
                        break;
                    case this.KILOMETER_ID: //km
                        converted = parseFloat(value/100/1000); //divided by meters / divided by km
                        break;
                    case this.METER_ID: //m
                        converted = parseFloat(value/100);
                        break;
                    case this.MILE_ID: //mi
                        converted = parseFloat(value*0.000006213712);
                        break;
                    case this.MILLIMETER_ID: //mm
                        converted = parseFloat(value*10);
                        break;
                    case this.NAUTICAL_MILE_ID: //nautical mile
                        converted = parseFloat(value*0.000005399568);
                        break;
                    case this.YARD_ID: //yard
                        converted = parseFloat(value*.01093613);
                        break;
                }
                break;
            case this.DECIMETER_ID: //decimeter
                switch(toUnitID){
                    default:
                        break;
                    case this.CENTIMETER_ID: //cm
                        converted = parseFloat(value * 10);
                        break;
                    case this.FEET_INCHES_ID: //feet
                        return this.#convertToFeetInchesString(value, fromUnitID, precision);
                        break;
                    case this.FEET_DECIMAL:
                        converted = parseFloat(value*.328084);
                        break;
                    case this.INCHES_ID: //inch
                        converted = parseFloat(value*3.937008);
                        break;
                    case this.KILOMETER_ID: //km
                        converted = parseFloat(value/10/1000); //10 decimeters in a meter. 1000 m in km
                        break;
                    case this.METER_ID: //m
                        converted = parseFloat(value/10);
                        break;
                    case this.MILE_ID: //mi
                        converted = parseFloat(value*0.00006213712);
                        break;
                    case this.MILLIMETER_ID: //mm
                        converted = parseFloat(value*100);
                        break;
                    case this.NAUTICAL_MILE_ID: //nautical mile
                        converted = parseFloat(value*0.00005399568);
                        break;
                    case this.YARD_ID: //yard
                        converted = parseFloat(value*.1093613);
                        break;
                }
                break;
            case this.FEET_INCHES_ID: //feet
                //Convert to Inches and then return the inches
                if(this.debugLog){
                    console.log('*****************************************************************');
                    console.log('CONVERTING VALUE', value, 'FROM', fromUnitID, '=>', toUnitID);
                    console.log('RAW INCHES', rawInches);
                    console.log('*****************************************************************');
                }
                const {rawInches} = this.explodeFeetInchesString(value, precision);
                return this.#convertLengthUnit(rawInches, this.INCHES_ID, toUnitID, precision);
                break;
            case this.FEET_DECIMAL:
                const inches = parseFloat((parseFloat(value)*12).toFixed(precision));
                return this.#convertLengthUnit(inches, this.INCHES_ID, toUnitID, precision);
                break;
            case this.INCHES_ID: //inch
                switch(toUnitID){
                    default:
                        break;
                    case this.DECIMETER_ID: //decimeter
                        converted = parseFloat(value*.254);
                        break;
                    case this.FEET_INCHES_ID: //feet
                        return this.#convertToFeetInchesString(value, fromUnitID, precision);
                        break;
                    case this.FEET_DECIMAL:
                        converted = parseFloat(value/12);
                        break;
                    case this.CENTIMETER_ID: //cm
                        converted = parseFloat(value*2.54);
                        break;
                    case this.KILOMETER_ID: //km
                        converted = parseFloat(value*0.0000254);
                        break;
                    case this.METER_ID: //m
                        converted = parseFloat(value*0.0254);
                        break;
                    case this.MILE_ID: //mi
                        converted = parseFloat(value*0.00001578283);
                        break;
                    case this.MILLIMETER_ID: //mm
                        converted = parseFloat(value*25.4);
                        break;
                    case this.NAUTICAL_MILE_ID: //nautical mile
                        converted = parseFloat(value*0.0000137149);
                        break;
                    case this.YARD_ID: //yard
                        converted = parseFloat(value*0.02777778);
                        break;
                }
                break;
            case this.KILOMETER_ID: //km
                switch(toUnitID){
                    default:
                        break;
                    case this.DECIMETER_ID: //decimeter
                        converted = parseFloat(value*10000);
                        break;
                    case this.FEET_INCHES_ID: //feet
                        return this.#convertToFeetInchesString(value, fromUnitID, precision);
                        break;
                    case this.FEET_DECIMAL:
                        converted = parseFloat(value*3280.84);
                        break;
                    case this.INCHES_ID: //inch
                        converted = parseFloat(value*3280.84*12);
                        break;
                    case this.CENTIMETER_ID: //cm
                        converted = parseFloat(value*100000);
                        break;
                    case this.METER_ID: //m
                        converted = parseFloat(value*1000);
                        break;
                    case this.MILE_ID: //mi
                        converted = parseFloat(value*0.6213712);
                        break;
                    case this.MILLIMETER_ID: //mm
                        converted = parseFloat(value*1000000);
                        break;
                    case this.NAUTICAL_MILE_ID: //nautical mile
                        converted = parseFloat(value*0.5399568);
                        break;
                    case this.YARD_ID: //yard
                        converted = parseFloat(value*1093.613);
                        break;
                }
                break;
            case this.METER_ID: //m
                switch(toUnitID){
                    default:
                        break;
                    case this.DECIMETER_ID: //decimeter
                        converted = parseFloat(value*10);
                        break;
                    case this.FEET_INCHES_ID: //feet
                        return this.#convertToFeetInchesString(value, fromUnitID, precision);
                        break;
                    case this.FEET_DECIMAL:
                        converted = parseFloat(value*3.28084);
                        break;
                    case this.INCHES_ID: //inch
                        converted = parseFloat(value*39.37008);
                        break;
                    case this.KILOMETER_ID: //km
                        converted = parseFloat(value/1000);
                        break;
                    case this.CENTIMETER_ID: //cm
                        converted = parseFloat(value*100);
                        break;
                    case this.MILE_ID: //mi
                        converted = parseFloat(value*0.0006213712);
                        break;
                    case this.MILLIMETER_ID: //mm
                        converted = parseFloat(value*1000);
                        break;
                    case this.NAUTICAL_MILE_ID: //nautical mile
                        converted = parseFloat(value*0.0005399568);
                        break;
                    case this.YARD_ID: //yard
                        converted = parseFloat(value*1.093613);
                        break;
                }
                break;
            case this.MILE_ID: //mi
                switch(toUnitID){
                    default:
                        break;
                    case this.DECIMETER_ID: //decimeter
                        converted = parseFloat(value*16093.44);
                        break;
                    case this.FEET_INCHES_ID: //feet
                        return this.#convertToFeetInchesString(value, fromUnitID, precision);
                        break;
                    case this.FEET_DECIMAL:
                        converted = parseFloat(value*5280)
                        break;
                    case this.INCHES_ID: //inch
                        converted = parseFloat(value*5280*12);
                        break;
                    case this.KILOMETER_ID: //km
                        converted = parseFloat(value*1.609344);
                        break;
                    case this.METER_ID: //m
                        converted = parseFloat(value*1609.344);
                        break;
                    case this.CENTIMETER_ID: //cm
                        converted = parseFloat(value*160934.4);
                        break;
                    case this.MILLIMETER_ID: //mm
                        converted = parseFloat(value*1609344);
                        break;
                    case this.NAUTICAL_MILE_ID: //nautical mile
                        converted = parseFloat(value*0.8689762);
                        break;
                    case this.YARD_ID: //yard
                        converted = parseFloat(value*1760);
                        break;
                }
                break;
            case this.MILLIMETER_ID: //mm
                switch(toUnitID){
                    default:
                        break;
                    case this.DECIMETER_ID: //decimeter
                        converted = parseFloat(value/100);
                        break;
                    case this.FEET_INCHES_ID: //feet
                        return this.#convertToFeetInchesString(value, fromUnitID, precision);
                        break;
                    case this.FEET_DECIMAL:
                        converted = parseFloat(value*0.00328084);
                        break;
                    case this.INCHES_ID: //inch
                        converted = parseFloat(value*0.03937008);
                        break;
                    case this.KILOMETER_ID: //km
                        converted = parseFloat(value/1000/1000);
                        break;
                    case this.METER_ID: //m
                        converted = parseFloat(value/1000);
                        break;
                    case this.MILE_ID: //mi
                        converted = parseFloat(value*0.0000006213712);
                        break;
                    case this.CENTIMETER_ID: //cm
                        converted = parseFloat(value/10);
                        break;
                    case this.NAUTICAL_MILE_ID: //nautical mile
                        converted = parseFloat(value*0.0000005399568);
                        break;
                    case this.YARD_ID: //yard
                        converted = parseFloat(value*0.001093613);
                        break;
                }
                break;
            case this.NAUTICAL_MILE_ID: //nautical mile
                switch(toUnitID){
                    default:
                        break;
                    case this.DECIMETER_ID: //decimeter
                        converted = parseFloat(value*18520);
                        break;
                    case this.FEET_INCHES_ID: //feet
                        return this.#convertToFeetInchesString(value, fromUnitID, precision);
                        break;
                    case this.FEET_DECIMAL:
                        converted = parseFloat(value*6076.115);
                        break;
                    case this.INCHES_ID: //inch
                        converted = parseFloat(value*72913.39);
                        break;
                    case this.KILOMETER_ID: //km
                        converted = parseFloat(value*1.852);
                        break;
                    case this.METER_ID: //m
                        converted = parseFloat(value*1852);
                        break;
                    case this.MILE_ID: //mi
                        converted = parseFloat(value*1.150779);
                        break;
                    case this.MILLIMETER_ID: //mm
                        converted = parseFloat(value*1852*1000);
                        break;
                    case this.CENTIMETER_ID: //cm
                        converted = parseFloat(value*1852*100);
                        break;
                    case this.YARD_ID: //yard
                        converted = parseFloat(value*2025.372);
                        break;
                }
                break;
            case this.YARD_ID: //yard
                switch(toUnitID){
                    default:
                        break;
                    case this.DECIMETER_ID: //decimeter
                        converted = parseFloat(value*9.144);
                        break;
                    case this.FEET_INCHES_ID: //feet
                        return this.#convertToFeetInchesString(value, fromUnitID, precision);
                        break;
                    case this.FEET_DECIMAL:
                        converted = parseFloat(value*3);
                        break;
                    case this.INCHES_ID: //inch
                        converted = parseFloat(value*36);
                        break;
                    case this.KILOMETER_ID: //km
                        converted = parseFloat(value*0.0009144);
                        break;
                    case this.METER_ID: //m
                        converted = parseFloat(value*0.9144);
                        break;
                    case this.MILE_ID: //mi
                        converted = parseFloat(value*0.0005681818);
                        break;
                    case this.MILLIMETER_ID: //mm
                        converted = parseFloat(value*914.4);
                        break;
                    case this.NAUTICAL_MILE_ID: //nautical mile
                        converted = parseFloat(value*0.0004937365);
                        break;
                    case this.CENTIMETER_ID: //cm
                        converted = parseFloat(value*91.44);
                        break;
                }
                break;
        }
        return parseFloat(converted.toFixed(precision));
    }
    #convertToFeetInchesString(value, fromUnitID, precision = 0) {
        const inches = this.#convertLengthUnit(value, fromUnitID, this.INCHES_ID, precision);
        const feet = parseInt(Math.floor(inches) / 12);
        const remainingInches = parseFloat(parseFloat(inches - (feet * 12)).toFixed(precision));
        return `${feet}'${remainingInches}"`;
    }
}

export default MeasurementUnits;