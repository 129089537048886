import {create} from 'zustand'
import {produce} from 'immer'



export const DISPLAY_OPTIONS = {
    altLogin: 'altLogin',
    deviceLogin: 'deviceAdmin',
    updateNotice: 'newUpdate',
}

const initDeviceForm=()=>{
    return {
        pin: '',
        adminVerified: false
    }
}
const initAltLoginForm=()=>{
    return {
        loginMethod: '',
        email: '',
        pin: '',
        selectedTeam: '',
        selectedAthlete: '',
    }
}

export const useAnnouncementStore=create((set, get)=> ({
    modalHeader: {
        display: false, //Display the modal (show it to the user)
        title: '', //title of the modal
        size: 'sm',  //size of the modal using @mui breakpoints
        section: 'altAccount' //section to display: ['device','altAccount']
    },
    displayPin: false, //UnMask/Show Login Pin for Device Adming, Alt Login Form

    //Variables for the device admin form
    deviceLogin: initDeviceForm(),

    //Variables for the alt account login form
    teamList: [], //list of teams for the loginForm
    athleteList: [], //list of athletes for the loginForm
    loginForm: initAltLoginForm(),

    /*********************************************************************/
    /*******************MANAGING MODAL AND SETTINGS***********************/
    /*********************************************************************/
    setDisplay: (display, section = '')=>set(produce((state)=>{
        const {modalHeader} = get();
        let title, size = 'sm';
        if(section === DISPLAY_OPTIONS.altLogin){
            //Alternate login
            title = 'Athlete Login';
        } else if(section === DISPLAY_OPTIONS.deviceLogin){
            //Device admin login
            title = 'Enter Administrator Pin';

        } else {
            //updates
            title = 'Get The Latest Version';
        }


        let mh = {...modalHeader};
        mh.display = display;
        mh.section = section;
        mh.title = title;
        mh.size = size;
        state.modalHeader = mh;
    })),
    setDisplayPin: (bool)=>set(produce((state)=>{
        state.displayPin = Boolean(bool);
    })),

    initForms: ()=>set(produce((state)=>{
        state.teamList = [];
        state.athleteList = [];
        state.loginForm = initAltLoginForm();
        state.deviceLogin = initDeviceForm();
    })),
    closeModal: ()=>set(produce((state)=>{
        const {modalHeader} = get();
        let mh = {...modalHeader};
        mh.display = false;
        state.modalHeader = mh;
        state.athleteList = [];
        state.teamList = [];
        state.displayPin = false;
        state.loginForm = initAltLoginForm();
        state.deviceLogin = initDeviceForm();
    })),



    /*********************************************************************/
    /*******************DEVICE ADMIN LOGIN FORM FOR MASTERS***************/
    /*********************************************************************/

    updateDeviceLogin: (field, value)=>set(produce((state)=>{
        let dl = {...state.deviceLogin};
        dl[field] = value;
        state.deviceLogin = dl;
    })),

    /*********************************************************************/
    /*******************ALT ACCOUNT LOGIN FORM FOR DEVICES****************/
    /*********************************************************************/

    initLoginForm:(method)=>set(produce((state)=>{
        let loginForm = initAltLoginForm();
        loginForm.loginMethod = method;
        state.athleteList = [];
        state.loginForm = loginForm;
    })),
    updateLoginForm: (field, value)=>set(produce((state)=>{
        const {loginForm} = get();
        let lf = {...loginForm};
        lf[field] = value;
        state.loginForm = lf;
    })),
    setTeamList: (teams)=>set(produce((state)=>{
        state.teamList = teams;
    })),
    setAthleteList: (athletes)=>set(produce((state)=>{
        state.athleteList = athletes;
    })),
    setSelectedAthlete: (athlete)=> set(produce((state)=>{
        state.updateLoginForm('selectedAthlete', athlete);
    }))
}));

export default useAnnouncementStore;