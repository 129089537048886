import React from 'react';
import Stack from "@mui/material/Stack";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import AuthUserDropdown from "../../../../shared/widgets/AuthUserDropdown";
import {
    IconButton,
    Typography,
    Button,
 Tooltip
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Logo from "../../../../shared/Logo";
import {SIDEBAR_STYLES, SIDEBAR_VARIANTS} from "@jumbo/utils/constants";
import {PRODUCTION_STATUS} from "fit/system/FITConstants";
import User from 'fit/system/User';
import UserPreferences from "fit/system/UserPreferences";

import useJumboHeaderTheme from "@jumbo/hooks/useJumboHeaderTheme";
import {Logout} from "@mui/icons-material";

import {getSpacing} from "fit/system/UtilityFunctions";
import Alert from "fit/components/Alert";
import AlertSmall from "fit/components/AlertSmall";
import HeaderSmallAlert from "fit/components/System/VersionAlerts/HeaderSmallAlert";

const Header = ({scrolled}) => {


    let {sidebarOptions, setSidebarOptions} = useJumboLayoutSidebar();
    const {headerTheme} = useJumboHeaderTheme();

    const user = new User();
    const deviceAccount = user.getDeviceAccount();
    const authorized = user.getLoggedInStatus();
    const userInfo = user.getUserInfo();
    const {online} = user.getFitUserStore();

    const {firstName, lastName} = userInfo;
    const onboarded = user.getOnboarded();
    const validDevice = authorized && deviceAccount;
    const altAccount = user.getDeviceSubAccount();
    const altSet = altAccount != null;

    let altAccountHeadline = '';
    if(altSet){
        altAccountHeadline = `${altAccount.firstName} ${altAccount.lastName} Signed In`
    }

    const altDivStyle={
        position: 'absolute',
        width: '100%',
        zIndex: 10000,
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'bottom',
        pointerEvents: 'none',
        marginLeft: '-48px',
        minHeight: '80px',
    };
    const alertStyle={
        position: 'relative',
        pointerEvents: 'auto'
    }

    const noScrollDim = '40px';
    const scrollDim = '20px';

    const baseSX={
        maxHeight: noScrollDim,
        maxWidth: noScrollDim,
        transition: 'height 2s',
    }
    const scrollSx = {
    //    maxWidth: scrollDim,
    //    maxHeight: scrollDim,
        transform: 'scale(.8)',
    }

    const adjustedSx = scrolled ? scrollSx : {};

    return (
        <React.Fragment>
            {
                (
                    sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER
                    || sidebarOptions.variant === SIDEBAR_VARIANTS.TEMPORARY
                    || (sidebarOptions.variant === SIDEBAR_VARIANTS.PERSISTENT && !sidebarOptions.open)
                ) &&
                (
                    authorized && onboarded && !deviceAccount
                ) &&
                    <Tooltip title={'Open Menu'} placement={'right-end'}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            sx={{
                                ml: sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER ? -2 : -3, //-3 value was 0
                                mr: 1,
                                ...baseSX,
                                ...adjustedSx

                            }}
                            onClick={() => setSidebarOptions({open: !sidebarOptions.open})}
                        >
                            {
                                sidebarOptions?.open ? <MenuOpenIcon/> : <MenuIcon/>
                            }
                        </IconButton>
                    </Tooltip>


            }
            { //*******************Device Account Name ('Rack 01')*************************
                validDevice && <Typography variant={'h1'} sx={{color: '#FFF'}}>{firstName} {lastName}</Typography>
            }
            { //*********SCROLLING STATUS***************** DELETE WHEN DONE
               !PRODUCTION_STATUS && false && <Typography variant={'h3'} sx={{color: '#FFF'}}>_scr: {scrolled ? 1 : 0}</Typography>
            }
            <HeaderSmallAlert style={{marginRight: getSpacing('iconAlignment')}}/>
            <AlertSmall
                status={'warning'}
                visible={!online}
                message={'Buteo In Offline Mode'}
                tip={'Disconnected'}
            />



            <div style={altDivStyle}>
                <div style={{display: 'block', position: 'relative', top: getSpacing()}}>
                    <Alert
                        style={alertStyle}
                        status={'success'}
                        visible={validDevice && altSet}
                        headline={altAccountHeadline}
                    >
                        <Button
                            size={'small'}
                            fullWidth
                            variant={'contained'}
                            color={'secondary'}
                            onClick={()=>user.logoutAlternateAccount()}><Logout/> Logout
                        </Button>
                    </Alert>
                </div>
            </div>
            {
                sidebarOptions?.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER &&
                <Logo sx={{mr: 3}} mode={headerTheme.palette.mode ?? "light"} scrolled={scrolled}/>
            }
            <Stack direction="row" alignItems="center" spacing={1.25} sx={{ml: "auto"}}>
                {authorized &&
                    <AuthUserDropdown
                        scrolled={scrolled}
                    />
                }
            </Stack>
        </React.Fragment>
    );
};

export default Header;

